import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../services/api.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  wordListTrainer: Array<any> = [];
  msgTextRight = '';
  msgTextFalse = '';

  constructor(private api: ApiService, private cookie: CookieService) {}

  @ViewChild('word') word!: ElementRef;
  @ViewChild('translated') translated!: ElementRef;
  tempTranslated = '';

  
  ngOnInit(): void {
    this.getWordList();
  }

  getWordList() {
    let userId = this.cookie.get('userId');
    userId = userId.substr(3, userId.length - 4);
    this.api.getKeysfilter(userId).subscribe((res) => {
      this.wordListTrainer = res;
      console.log(this.wordListTrainer);
      this.nextWord();
    });
  }

  nextWord() {
    const zufallWord =
      this.wordListTrainer[
        Math.floor(Math.random() * this.wordListTrainer.length)
      ];
    this.word = zufallWord.words.word;
    this.tempTranslated = zufallWord.words.translated;
  }

  compare() {
    let translated = this.translated.nativeElement.value.trim();
    if (translated === this.tempTranslated) {
      this.msgTextRight = 'Right';
      this.msgTextFalse = '';
    } else {
      this.msgTextFalse = 'False';
      this.msgTextRight = '';
    }

    this.translated.nativeElement.value = '';
    this.translated.nativeElement.focus()
    this.nextWord();
  }

  clear() {}
}
