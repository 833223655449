<div
  class="mt-6 is-mobile"
  style="display: flex; justify-content: center; align-items: center"
>
  <div class="modal-background"></div>
  <div class="modal-card" style="border-radius: 15px">
    <form [formGroup]="login" (ngSubmit)="loginUser()">
      <section class="modal-card-body">
        <button
          style="position: absolute; top: 5px; right: 5px"
          class="delete"
          aria-label="close"
          (click)="updateToggle()"
        ></button>
        <div style="text-align: center">
          <span class="material-symbols-outlined m-4" style="font-size: 60px">
            login
          </span>
        </div>

        <div class="field">
          <input
          (keydown.enter)="loginUser()"
            style="border-radius: 20px"
            class="input is-info is-small"
            type="text"
            placeholder="E-Mail*"
            formControlName="email"
          />
        </div>
        <div class="field">
          <input
          (keydown.enter)="loginUser()"
            style="border-radius: 20px"
            class="input is-small is-info"
            type="password"
            placeholder="Password*"
            formControlName="password"
          />
        </div>
        <div style="text-align: center; color: red" *ngIf="reqRes" class="mt-2">
          <p>{{ reqRes }}</p>
        </div>
        <div style="text-align: center; color: red" *ngIf="msg" class="mt-2">
          <p>{{ msg }}</p>
        </div>
        <div class="field">
          <button
          (keydown.enter)="loginUser()"
            class="button is-info is-small mr-2"
            type="submit"
            style="border-radius: 20px; width: 100%"
          >
            Login
          </button>
        </div>
      </section>
    </form>
  </div>
</div>
