<div class="p-3" style="text-align: center">
  <span
    class="material-symbols-outlined"
    style="text-align: center; color: hsl(204, 74%, 31%); font-size: 50px"
  >
    contact_support
  </span>
</div>
<h1 id="word" style="text-align: center; color: rgb(250, 0, 0)">{{ word }}</h1>

<div class="columns is-centered m-3">
  <div class="column is-half">
    <div class="inputs is-small mb-4" style="text-align: center">
      <input
      (keydown.enter)="compare()"
        style="border-radius: 12px; width: 100%"
        class="input is-info is-small mb-4"
        type="input"
        #translated
      />
      <p class="mb-3">
        <strong style="color: rgb(3, 168, 30); font-size: 25px">{{
          msgTextRight
        }}</strong>
      </p>
      <p class="mb-3">
        <strong style="color: rgb(168, 3, 3); font-size: 25px">{{
          msgTextFalse
        }}</strong>
      </p>
      <button
        style="border-radius: 12px"
        type="submit"
        id="submitBtn"
        
        (click)="compare()"
        class="button is-info is-outlined mb-6" hidden
      >
        Compare
      </button>
    </div>
  </div>
</div>
