import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { UserSignin } from '../interface/user-signin';
import { UserSignup } from '../interface/user-signup';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private http: HttpClient, private cookie: CookieService) {}

  createUser(user: UserSignup): Observable<UserSignup> {
    return this.http.post<UserSignup>(`api/auth/register`, user);
  }

  loginUser(user: UserSignin): Observable<UserSignin> {
    return this.http.post<UserSignin>(`api/auth/login`, user);
  }

  logout(): Observable<any> {
    this.cookie.deleteAll();
    localStorage.clear();
    return this.http.get<any>(`api/usr/signout`);
  }

  getCookie() {
    const role = this.cookie.get('role');
    if (role === 'user') {
      return true;
    } else {
      return false;
    }
  }
  //prüfen, ob es ein Token in SessionStorage gibt
  loggedIn() {
    return !!localStorage.getItem('token');
  }

  // tocken von sessionStorage holen und speichern
  getToken() {
    localStorage.setItem('token', this.cookie.get('token'));
    localStorage.setItem('userId', this.cookie.get('userId'));
    return localStorage.getItem('token');
  }
}
