import { Component, OnInit } from '@angular/core';
import { response } from 'express';
import { word } from './interface/word';
import { ApiService } from './services/api.service';
import { CookieService } from 'ngx-cookie-service';

@Component({ selector: 'app-root', templateUrl: 'app.component.html' })
export class AppComponent implements OnInit {
  title(title: any) {
    throw new Error('Method not implemented.');
  }

  part: any = 'ALL';
  private word: word = {
    words: {
      word: '',
      translated: '',
      exp: [],
    },
  };

  constructor(private apiService: ApiService, private cookie: CookieService) {}
  ngOnInit(): void {}

  onGetKeys(): void {
    let _userId = this.cookie.get('_userId');
    _userId = _userId.substr(3, _userId.length - 4);

    this.apiService.getKeysfilter(_userId).subscribe(
      (response) => {
        console.log(response);
      },
      (error) => console.log(error),
      () => console.log('Done geeting Keywords')
    );
  }

  onCreateKey(): void {
    this.apiService.createKey(this.word).subscribe(
      (response) => console.log(response),
      (error) => console.log(error),
      () => console.log('Done creating Keywords')
    );
  }
}
