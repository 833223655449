import { Injectable, Injector } from '@angular/core';
import { HttpInterceptor } from '@angular/common/http';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class TokenInterceptorService implements HttpInterceptor {
  constructor(private injector: Injector) {}
  intercept(
    req: { clone: (arg0: { setHeaders: { token: any } }) => any },
    next: { handle: (arg0: any) => any }
  ) {
    let _userService = this.injector.get(UserService);
    let tokenizedReq = req.clone({
      setHeaders: {
        token: _userService.getToken(),
      },
    });
    return next.handle(tokenizedReq);
  }
}
