import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { word } from '../interface/word';
import { catchError, retry } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private http: HttpClient) {}

  private handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        '   Backend returned code ${error.status}, body was:',
        error.error
      );
    }
    // Return an observable with a user-facing error message.
    return throwError(
      () => new Error('Something bad happened; please try again later.')
    );
  }

  getKeysfilter(userId: any): Observable<word[]> {
    return this.http.get<word[]>(`/api/words/${userId}`);
  }

  createKey(word: word): Observable<word> {
    return this.http
      .post<any>(`/api/words`, word)
      .pipe(catchError(this.handleError));
  }

  deleteKey(id: any): Observable<any> {
    return this.http
      .delete(`/api/words/${id}`)
      .pipe(catchError(this.handleError));
  }

  deleteExp(exp: any): Observable<any> {
    return this.http
      .post('/api/deleteexample', { exp })
      .pipe(catchError(this.handleError));
  }

  update(id: any, item: any) {
    return this.http
      .put(`/api/words/${id}`, item)
      .pipe(catchError(this.handleError));
  }
}

function tab(): import('rxjs').OperatorFunction<word[], word[]> {
  throw new Error('Function not implemented.');
}
