import { Component, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../services/user.service';
import { UserSignup } from '../interface/user-signup';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent {
  @ViewChild('name') name!: ElementRef;
  @ViewChild('lastname') lastname!: ElementRef;
  @ViewChild('email') email!: ElementRef;
  @ViewChild('password') password!: ElementRef;

  constructor(
    private _userService: UserService,
    private _router: Router,
    private cookie: CookieService
  ) {}

  reqRes: any;
  toggleSignup: boolean = false;

  signupUsr = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.minLength(4)]),
    password: new FormControl('', [
      Validators.required,
      Validators.minLength(6),
    ]),
    confPass: new FormControl('', [
      Validators.required,
      Validators.minLength(6),
    ]),
  });
  msg: String = '';
  ngOnInit(): void {}

  newUser: any = {
    email: '',
    password: '',
  };

  createUser() {
    this.newUser.email = this.signupUsr.value.email;
    this.newUser.password = this.signupUsr.value.password;
    if (
      this.signupUsr.value.email === '' ||
      this.signupUsr.value.password != this.signupUsr.value.confPass
    ) {
      this.msg = 'Please check your INPUT ';
      this.reqRes = '';
    } else {
      this._userService.createUser(this.newUser).subscribe(
        (res) => {
          this.reqRes = res;
          if (this.reqRes.error) {
            this.reqRes = this.reqRes.error;
          } else {
            this._router.navigate(['/']);
          }
        },
        (err) => {
          console.log(err);
          this.msg = err.error.message;
          this.reqRes = '';
        }
      );
    }
  }

  updateToggle() {
    this._router.navigate(['/']);
  }
}
