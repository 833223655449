import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter',
})
export class FilterPipe implements PipeTransform {
  transform(items: any[], filteredString: string): any {
    if (!items) return [];
    if (!filteredString) return items;
    filteredString = filteredString.toLowerCase();
    return items.filter((item) => {
      return (
        item.words.word.toLowerCase().includes(filteredString) ||
        item.words.translated.toLowerCase().includes(filteredString)
      );
    });
  }
}
