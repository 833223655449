import { Component, ElementRef, ViewChild } from '@angular/core';
import { ApiService } from '../services/api.service';
import { Router } from '@angular/router';
import { FormControl, FormGroup, FormsModule } from '@angular/forms';

@Component({
  selector: 'app-edit-word',
  templateUrl: './edit-word.component.html',
  styleUrls: ['./edit-word.component.scss'],
  providers: [FormsModule],
})
export class EditWordComponent {
  constructor(private api: ApiService, private rtr: Router) {
    this.oldDoc = this.rtr.getCurrentNavigation()?.extras.state;
  }
  oldDoc: any;
  editToggle: boolean = true;
  doc: any;
  examples: any;

  // @ViewChild('editTranslatedText') editTranslatedText!: ElementRef;
  // @ViewChild('editWordText') editWordText!: ElementRef;
  // @ViewChild('exp') exp!: ElementRef;

  ngOnInit() {
    console.log('the old doc', this.oldDoc);
    this.doc = new FormGroup({
      word: new FormControl(this.oldDoc.word),
      translated: new FormControl(this.oldDoc.translated),
      exp: new FormControl(this.oldDoc.exp),
    });
  }

  update() {
    let editedWord = {
      userId: this.oldDoc.userId,
      words: {
        word: this.doc.value.word,
        translated: this.doc.value.translated,
        exp: this.doc.value.exp,
      },
    };
    console.log(editedWord);
    this.api.update(this.oldDoc._id, editedWord).subscribe((res) => {
      this.rtr.navigateByUrl('/view');
    });
  }
  closeModal() {
    this.editToggle = false;
    this.rtr.navigateByUrl('/view');
  }
}
