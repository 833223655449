<div class="container is-max-desktop p-2">
  <div style="text-align: center"><h2>Add Word</h2></div>
  <form [formGroup]="newWordForm" (ngSubmit)="createWord()">
    <div class="field">
      <input
      placeholder="Word"
        formControlName="word"
        class="input is-info is-small"
        style="border-radius: 12px"
        type="input"
      />
    </div>
    <div class="field">
      <input
        placeholder="Translated"
        formControlName="translated"
        style="border-radius: 12px"
        class="input is-info is-small"
        type="input"
      />
    </div>
    <div class="field">
      <input
        placeholder="Example"
        formControlName="exp"
        style="border-radius: 12px"
        class="input is-info is-small"
        type="input"
      />
    </div>
    <p *ngIf="msg" style="color: red" class="is-danger is-small">
      {{ msg }}
    </p>
    <button
      style="border-radius: 12px; width: 100%"
      type="submit"
      class="button is-small is-primary"
    >
      Add
    </button>
  </form>
  <input
    class="input is-small is-info mt-3"
    style="border-radius: 12px"
    type="text"
    placeholder="Search..."
    [(ngModel)]="filteredString"
  />
  <div class="table-container is-fullwidth">
    <table class="table is-striped is-hoverable is-fullwidth is-mobile">
      <thead>
        <tr style="font-size: 12px">
          <th>WORD</th>
          <th>TRANSLATE</th>
          <th style="text-align: center">EXAMPLE</th>
          <th style="text-align: center">EDIT</th>
          <th style="text-align: center">DELETE</th>
        </tr>
      </thead>
      <tbody>
        <tr
          style="font-size: 12px"
          *ngFor="let doc of wordList | filter : filteredString"
        >
          <td>
            {{ doc.words.word }}
          </td>
          <td>
            {{ doc.words.translated }}
          </td>
          <td style="text-align: center; color: blue">
            <span
              class="material-symbols-outlined is-small"
              (click)="openExample(doc)"
            >
              info
            </span>
          </td>
          <td style="text-align: center; color: rgb(118, 118, 177)">
            <span
              class="material-symbols-outlined is-small"
              (click)="forward(doc)"
            >
              edit
            </span>
          </td>
          <td style="text-align: center; align-items: center; color: red">
            <span class="material-symbols-outlined" (click)="delete(doc._id)">
              close
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div class="container is-max-desktop">
  <div *ngIf="exampleToggle" class="modal is-info is-active is-mobile">
    <div class="modal-background is-info"></div>
    <div class="modal-card p-2">
      <header class="modal-card-head">
        <p class="modal-card-title">Example</p>
      </header>
      <section class="modal-card-body" *ngFor="let exp of examples">
        <textarea
          [readOnly]="true"
          class="textarea is-info is-small"
          style="border-radius: 15px"
          >{{ exp }}</textarea
        >
      </section>
      <footer class="modal-card-foot">
        <button
          class="button is-small is-outlined is-info"
          style="border-radius: 20px; width: 100%"
          (click)="closeExample()"
        >
          Zurück
        </button>
      </footer>
    </div>
  </div>
</div>
