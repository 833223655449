import { Component, ViewChild, ElementRef } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { UserService } from '../services/user.service';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent {
  @ViewChild('navBurger') navBurger!: ElementRef;
  @ViewChild('navMenu') navMenu!: ElementRef;

  constructor(
    private _userService: UserService,
    private _router: Router,
    private cookie: CookieService
  ) {}

  toggleNavbar() {
    this.navBurger.nativeElement.classList.toggle('is-active');
    this.navMenu.nativeElement.classList.toggle('is-active');
  }

  onLogout() {
    this._userService.logout().subscribe(
      (response) => {
        sessionStorage.removeItem('token');
        localStorage.clear();
        this.cookie.deleteAll();
        console.log(response);
      },
      (error) => console.log(error),
      () => console.log('signout seccussful')
    );
  }
}
