import { Component, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../services/user.service';
import { UserSignin } from '../interface/user-signin';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  @ViewChild('email') email!: ElementRef;
  @ViewChild('password') password!: ElementRef;

  constructor(private userService: UserService, private _router: Router) {}
  ngOnInit(): void {}

  login = new FormGroup({
    email: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required),
  });
  newUser: any = {
    email: '',
    password: '',
  };
  toggleLogin: boolean = false;
  reqRes: any;
  msg: any;
  loginUser() {
    this.newUser.email = this.login.value.email;
    this.newUser.password = this.login.value.password;
    if (this.login.value.email === '' || this.login.value.password === '') {
      this.msg = 'Please check your INPUT ';
    } else {
      this.userService.loginUser(this.newUser).subscribe(
        (res) => {
          this._router.navigate(['/']);
          this.reqRes = res;
          this.reqRes = this.reqRes.error;
        },
        (err) => {
          console.log(err);
          this.msg = err.error.message;
          this.reqRes = '';
        }
      );
    }
  }

  updateToggle() {
    this.toggleLogin = false;
    this._router.navigate(['/']);
  }
}
