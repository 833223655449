import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AddwordComponent } from './addword/addword.component';
import { HomeComponent } from './home/home.component';

import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { AuthGuard } from './services/auth.guard';
import { EditWordComponent } from './edit-word/edit-word.component';

const routes: Routes = [
  { path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full',
  },
  { path: 'view', component: AddwordComponent, canActivate: [AuthGuard] },
  { path: 'editword', component: EditWordComponent },
  { path: `login`, component: LoginComponent },
  { path: `register`, component: RegisterComponent },
  { path: `**`, component: HomeComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
