<div class="container is-max-desktop">
  <div *ngIf="editToggle" class="modal is-info is-active is-mobile">
        <div class="modal-background"></div>
        <div class="modal-card p-2">
      <form [formGroup]="doc" (ngSubmit)="update()">

          <header class="modal-card-head">
            <p class="modal-card-title">EDIT</p>
          </header>
          <section class="modal-card-body">
            <label for="word">WORD</label>
            <input
              style="border-radius: 12px; width: 100%"
              formControlName="word"
              class="input is-info is-small mb-4"
              type="input"
            />
            <label for="translated">Translated</label>
            <input
              style="border-radius: 12px; width: 100%"
              formControlName="translated"
              class="input is-info is-small mb-4"
              type="input"
            />
            <label for="beispiel">Example</label>

            <textarea
              style="border-radius: 12px; width: 100%"
              formControlName="exp"
              class="textarea is-info is-small"
            ></textarea>
          </section>
          <footer class="modal-card-foot">
            <button
              style="border-radius: 12px; width: 50%"
              class="button is-small is-outlined is-primary"
              (click)="update()"
            >
              Save
            </button>
            <button
              style="border-radius: 12px; width: 50%"
              class="button is-small is-outlined is-danger"
              (click)="closeModal()"
            >
              Cancel
            </button>
          </footer> </form>
        </div>
     
    </div>
  </div>
