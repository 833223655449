<nav
  class="navbar has-shadow is-small"
  role="navigation"
  aria-label="main navigation"
>
  <a
    role="button"
    class="navbar-burger"
    data-target="navMenu"
    aria-label="menu"
    aria-expanded="false"
  >
    <span aria-hidden="true"></span>
    <span aria-hidden="true"></span>
    <span aria-hidden="true"></span>
  </a>

  <div class="navbar-menu" id="navMenu">
    <div class="navbar-start">
      <a class="navbar-item" href="/">Trainer</a>
      <a class="navbar-item" href="view">Words</a>
    </div>

    <div class="navbar-end">
      <div class="navbar-item">
        <div class="buttons">
          <a href="register">
            <button
              class="button is-info is-outlined mr-1 is-small"
              style="border-radius: 12px"
            >
              Sign up
            </button>
          </a>

          <a href="login">
            <button
              class="button is-outlined mr-1 is-small"
              style="border-radius: 12px"
            >
              Sign in
            </button>
          </a>
          <a href="home">
            <button
              style="border-radius: 12px"
              class="button is-outlined is-danger is-small"
              (click)="onLogout()"
            >
              Sign out
            </button>
          </a>
        </div>
      </div>
    </div>
  </div>
</nav>
