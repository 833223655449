import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { elementAt } from 'rxjs';
import { ApiService } from '../services/api.service';
import { word } from '../interface/word';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-addword',
  templateUrl: './addword.component.html',
  styleUrls: ['./addword.component.scss'],
})
export class AddwordComponent implements OnInit {
  wordList: Array<any> = [];
  editToggle: boolean = false;
  exampleToggle: boolean = false;
  editExpToggle: boolean = false;
  doc: any;
  oldDoc: any;
  examples: string[] = []; // Initialize the examples array
  inputValue = '';
  filteredString: string = '';

  @ViewChild('selectedNiveau') selectedNiveau!: ElementRef;
  @ViewChild('translated') translated!: ElementRef;
  @ViewChild('addword') addword!: ElementRef;
  @ViewChild('word') word!: ElementRef;
  @ViewChild('requiredMsgGer') requiredMsgGer!: ElementRef;
  @ViewChild('requiredMsgArab') requiredMsgArab!: ElementRef;
  @ViewChild('exp') exp!: ElementRef;
  @ViewChild('editexample') editexample!: ElementRef;
  reqRes: any;
  msg: any;
  newWordForm = new FormGroup({
    word: new FormControl('', [Validators.required, Validators.minLength(2)]),
    translated: new FormControl('', [
      Validators.required,
      Validators.minLength(2),
    ]),
    exp: new FormControl(''),
  });

  newWord: word = {
    userId: '',
    words: {
      word: '',
      translated: '',
      exp: '',
    },
  };

  constructor(
    private api: ApiService,
    private cookie: CookieService,
    private rtr: Router
  ) {}

  ngOnInit() {
    this.getDataFormDB();
  }

  getDataFormDB() {
    let userId = this.cookie.get('userId');
    userId = userId.substr(3, userId.length - 4);
    this.api.getKeysfilter(userId).subscribe((res) => {
      this.wordList = res;
    });
  }

  createWord() {
    let userId = this.cookie.get('userId');
    userId = userId.substr(3, userId.length - 4);

    this.newWord = {
      userId: userId,
      words: {
        word: this.newWordForm.value.word?.trim(),
        translated: this.newWordForm.value.translated?.trim(),
        exp: this.newWordForm.value.exp,
      },
    };

    if (!this.newWordForm.value.word || !this.newWordForm.value.translated) {
      this.msg = 'Please check your INPUT';
      this.reqRes = '';
    } else {
      this.api.createKey(this.newWord).subscribe((res) => {
        this.reqRes = res;
        this.msg = '';
        this.reqRes = this.reqRes.message;
        
      });
      this.clearInput();
    }
    this.getDataFormDB();
  }

  clearInput() {

    this.newWordForm = new FormGroup({
      word: new FormControl(''),
      translated: new FormControl(''),
      exp: new FormControl(''),
    });
    // this.newWordForm.value.word = '';
    // this.newWordForm.value.translated = '';
    // this.newWordForm.value.exp = '';
  }

  delete(id: any) {
    this.api.deleteKey(id).subscribe((res) => {
      console.log(res);
      this.getDataFormDB();
    });
    
  }

  forward(item: any) {
    this.oldDoc = {
      _id: item._id,
      userId: item.userId,
      word: item.words.word,
      translated: item.words.translated,
      exp: item.words.exp,
    };
    console.log(this.oldDoc);
    this.rtr.navigateByUrl(`editword`, {
      state: this.oldDoc,
    });
  }

  closeModal() {
    this.editToggle = false;
    this.getDataFormDB();
  }

  openExample(doc: any) {
    this.exampleToggle = true;
    this.examples = doc.words.exp;
    console.log(this.doc);
  }

  closeExample() {
    this.exampleToggle = false;
  }

  closeExpEdit() {
    this.editExpToggle = false;
  }
}
